import React from "react";
import { IonPage, IonContent } from "@ionic/react";
import Header from "./Header";
import Footer from "./Footer";
import { GridContent } from "./copy";
import { classFilter } from "../util/dom";

interface PageLayoutProps {
  title: string;
  children: any;
  className?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <IonPage
      className={classFilter(
        " min-w-screen min-h-screen flex-col overflow-x-hidden ",
        className ?? ""
      )}
    >
      <Header />
      <IonContent fullscreen className="pb-safe-bottom ">
        <GridContent className="">{children}</GridContent>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default PageLayout;
