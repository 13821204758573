import { H1 } from "../components/copy";
import { Inner } from "../components/layout";
import PageLayout from "../components/PageLayout";

// type EventDetailsProps = {
//   event: Event;
// };

const EventDetails: React.FC = () => {
  // const { id } = useParams<{ id: string }>();

  // // Make sure the ID matches the event's ID
  // if (Number(id) !== event.id) {
  //   return <div>Event not found</div>;
  // }

  // // Convert the date to the desired format using Moment.js
  // const formattedDate = moment(event.date).format("MMMM D, YYYY");

  return (
    <PageLayout title={"Event Details"}>
      <Inner className="col-span-12 ml-auto mr-auto">
        <H1 className="text-center"> Event details page coming soon..!</H1>
      </Inner>
    </PageLayout>
  );
};

export default EventDetails;
