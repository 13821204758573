import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";

import Events from "./pages/Events";
import Shop from "./pages/Shop";
import "@ionic/react/css/core.css";
import MikeysCorner from "./pages/MikeysCorner";
import EventDetails from "./pages/EventDetails";
import PortfolioItem from "./pages/PortfolioItem";
import ShopItem from "./pages/ShopItem";

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet mode="md">
        <Route exact path="/portfolio">
          <Home />
        </Route>

        <Route exact path="/portfolio/details">
          <PortfolioItem />
        </Route>

        <Route exact path="/events">
          <Events />
        </Route>

        <Route exact path="/eventdetails">
          <EventDetails />
        </Route>

        <Route exact path="/shop">
          <Shop />
        </Route>
        <Route exact path="/shop/viewItem">
          <ShopItem />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/mikeyscorner">
          <MikeysCorner />
        </Route>
        <Route exact path="/">
          <Redirect to="/portfolio" />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
