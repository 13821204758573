import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
} from "@ionic/react";
import { Link, useLocation } from "react-router-dom";
import { Copy, H2, Label } from "./copy";

const Header: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setShowMenu(false);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [location]);

  return (
    <IonHeader className="">
      <IonToolbar className=" pb-0.5 md:pb-2 ">
        <div className=" flex">
          <H2 className="  hover:bg-romance-200 rounded-md w-fit hover:bg-opacity-25 md:ml-auto ml-3  mt-auto mb-auto  md:mr-auto md:mt-6">
            <Link to="/portfolio" className="w-fit p-2">
              The Maker
            </Link>
          </H2>
          <IonButton
            onClick={handleMenuClick}
            className="md:hidden ml-auto mr-2 text-black"
          >
            <Label className="text-gray-300">Menu</Label>
          </IonButton>
        </div>
        <div className="hidden md:flex mt-8">
          <IonButtons className="ml-auto mr-auto">
            <Link to="/portfolio">
              <IonButton
                className={`hover:bg-romance-200  rounded-md w-fit ml-2 hover:bg-opacity-25 font-semibold ${
                  location.pathname === "/portfolio" ? "  bg-romance-200 " : ""
                }`}
              >
                Portfolio
              </IonButton>
            </Link>
            <Link to="/events">
              <IonButton
                className={`hover:bg-romance-200  rounded-md w-fit ml-2 hover:bg-opacity-25 font-semibold ${
                  location.pathname === "/events" ? "  bg-romance-200 " : ""
                }`}
              >
                Events
              </IonButton>
            </Link>

            <Link to="/shop">
              <IonButton
                className={`hover:bg-romance-200  rounded-md w-fit ml-2 hover:bg-opacity-25 font-semibold ${
                  location.pathname === "/shop" ? "  bg-romance-200 " : ""
                }`}
              >
                My Shop
              </IonButton>
            </Link>

            <Link to="/about">
              <IonButton
                className={`hover:bg-romance-200  rounded-md w-fit ml-2 hover:bg-opacity-25 font-semibold ${
                  location.pathname === "/about" ? "  bg-romance-200 " : ""
                }`}
              >
                About
              </IonButton>
            </Link>
            <Link to="/contact">
              <IonButton
                className={`hover:bg-romance-200  rounded-md w-fit ml-2 hover:bg-opacity-25 font-semibold ${
                  location.pathname === "/contact" ? " bg-romance-200 " : ""
                }`}
              >
                Contact
              </IonButton>
            </Link>
          </IonButtons>
        </div>
      </IonToolbar>
      {showMenu && (
        <IonList>
          <IonItem
            className={`${
              location.pathname === "/portfolio" ? "underline" : ""
            }`}
            routerLink="/portfolio"
            onClick={() => setShowMenu(false)}
          >
            Portfolio
          </IonItem>
          <IonItem
            className={`${location.pathname === "/events" ? "underline" : ""}`}
            routerLink="/events"
            onClick={() => setShowMenu(false)}
          >
            Events
          </IonItem>
          {/* <IonItem
            className={`${
              location.pathname === "/shop" ? "underline" : ""
            }`}
            routerLink="/shop"
            onClick={() => setShowMenu(false)}
          >
            My Shop
          </IonItem> */}

          <IonItem
            className={`${location.pathname === "/about" ? "underline" : ""}`}
            routerLink="/about"
            onClick={() => setShowMenu(false)}
          >
            About
          </IonItem>
          <IonItem
            className={`${location.pathname === "/contact" ? "underline" : ""}`}
            routerLink="/contact"
            onClick={() => setShowMenu(false)}
          >
            Contact
          </IonItem>
        </IonList>
      )}
    </IonHeader>
  );
};

export default Header;
