import { Link } from "react-router-dom";
import { Copy, H1, H2, H3, H4, Label } from "../components/copy";
import { Inner } from "../components/layout";
import PageLayout from "../components/PageLayout";
import Swal from "sweetalert2";
import { useEffect, useRef } from "react";
import ImageComponent from "../components/image";
import { IonIcon } from "@ionic/react";
import {
  arrowForwardCircleOutline,
  calendarOutline,
  logoInstagram,
  peopleOutline,
} from "ionicons/icons";
import "./home.css";

export type PortfolioItem = {
  id: number;
  title: string;
  description: string;
  tags: string;
  price: string;
  coverPhoto: string;
  photos: string;
};

const Portfolio: React.FC = () => {
  // Check if today's date is March 31st, cumpleanos de mi hermana
  const today = new Date();
  const isMarch31st = today.getMonth() === 2 && today.getDate() === 31;

  useEffect(() => {
    if (isMarch31st) {
      Swal.fire({
        title:
          "🎂🎂 HAPPY 🥮🥮 BIRTHDAY 2 RACHELLELLLLLLL 🧁🧁🧁 YES YE YA YA YA 😈😈 🍰🍰🍰",
        icon: "success",
        confirmButtonText: "OK",
      });
      console.log(isMarch31st, "IS MARCH??");
    }
  }, []);

  return (
    <PageLayout title="Portfolio" className="">
      <Inner className="col-span-12 ml-auto mr-auto ">
        {/* <H1> Rachel The Maker </H1> */}
        <H2 className=" text-center w-5/6 sm:w-4/6 md:w-3/6 ml-auto mr-auto">
          Fashion Designer creating one&#8209;of&#8209;a&#8209;kind garments
          with vintage textiles.
        </H2>

        {/* <div className="mb-6 ">
          <ImageComponent
            fileName="rach.png"
            alt=""
            className="w-[10rem] z-1 h-[18rem] md:w-[20rem] md:h-[30rem] mx-auto object-cover rounded-full"
          />{" "}
          <Copy className="absolute  flex items-center bg-romance-300 bg-opacity-90 justify-center left-auto right-3  px-4 py-1 rounded-full z-10 -mt-20  w-fit">
            Rachel Maker
          </Copy>
        </div> */}
        <div className="relative w-fit  ml-auto mr-auto">
          <ImageComponent
            fileName="rach-bw.png"
            alt=""
            className="opacity-90 w-[12rem] h-[18rem] md:w-[24rem] md:h-[30rem] mx-auto object-cover rounded-full shadow-xl"
          />
          <Copy className="absolute text-center whitespace-normal -right-[6.5rem] md:-right-[10rem] md:text-3xl transform -translate-y-[200%] md:-translate-y-[400%]  bg-opacity-90 font-semibold  px-4 py-1 rounded-full ">
            Rachel Maker
          </Copy>
        </div>

        <div className="relative">
          <div className="flex flex-row scroll-container sm:justify-center sm:space-x-5 min-w-[100%]  mt-5  mb-12 overflow-x-scroll sm:overflow-x-auto mx-1 space-x-2  py-6">
            <Link to={"/events"}>
              <button className=" shadow-md whitespace-nowrap    bg-indigo-200 bg-opacity-80 rounded-xl px-6  py-1.5 flex-shrink-0">
                Upcoming Events{" "}
                <IonIcon
                  className="ml-2   translate-y-1"
                  icon={calendarOutline}
                />
              </button>
            </Link>
            <a
              href="https://www.depop.com/rachelthemaker/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <button className=" shadow-md whitespace-nowrap  bg-indigo-200 bg-opacity-80 rounded-xl px-6  py-1.5 flex-shrink-0">
                Shop My Depop{" "}
                <IonIcon
                  className="ml-2   translate-y-1"
                  icon={arrowForwardCircleOutline}
                />
              </button>
            </a>
            <Link to={"/contact"}>
              <button className=" shadow-md whitespace-nowrap  bg-indigo-200 bg-opacity-80 rounded-xl px-6  py-1.5 flex-shrink-0">
                Collaborate{" "}
                <IonIcon
                  className="ml-2   translate-y-1"
                  icon={peopleOutline}
                />
              </button>
            </Link>
          </div>
        </div>

        <div className=" ml-4 mr-auto w-fit mx-2">
          <H1 className="  relative  ">Check out my</H1>
          <H1 className="  relative   ml-12">portfolio projects</H1>
        </div>

        {/*  */}

        <div className="mx-8 mt-[3rem]">
          <H3 className=""> Fashion Show Looks </H3>
          <div className="collection-title ml-2">
            <H4 className=" underline">
              237 Collective x Iowa Pop Art Fashion Show
            </H4>
            <div className="-mt-4">
              <Label className="  hover:underline hover:text-blue-300">
                Photos by{" "}
                <a
                  href="https://www.instagram.com/tyourshoe/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ty Williamson{" "}
                  <IonIcon className="ml-2" icon={logoInstagram} />
                </a>
              </Label>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-4   ">
            <ImageComponent
              fileName="med-model-1.jpg"
              className=" h-fit m-2 object-cover  max-h-[30rem] max-w-[30rem]"
              alt={""}
            />
            <ImageComponent
              fileName="med-model-2.jpg"
              className="h-fit m-2  object-cover max-h-[30rem] max-w-[30rem]"
              alt={""}
            />
            <ImageComponent
              fileName="med-model-3.jpg"
              className="h-fit m-2  object-cover max-h-[30rem] max-w-[30rem]"
              alt={""}
            />
            <ImageComponent
              fileName="med-model-5.jpg"
              className="h-fit m-2  object-cover max-h-[30rem] max-w-[30rem]"
              alt={""}
            />
          </div>
        </div>
      </Inner>
    </PageLayout>
  );
};

export default Portfolio;
