import { IonButton, IonCheckbox } from "@ionic/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Copy, H1, H2, H3, Label } from "../components/copy";
import { Inner } from "../components/layout";
import PageLayout from "../components/PageLayout";
import "./Events.css";
import moment from "moment";
import ImageComponent from "../components/image";

export type Event = {
  id: number;
  title: string;
  date: string;
  time: string;
  description: string;
  description2?: string;
  url?: string;
  image: string;
  location: string;
};

type MonthEvents = {
  [monthYear: string]: Event[];
};

const Events: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([
   
    {
      id: 2,
      title: "80/35 Music Festival",
      date: "2023-07-07",
      time: "6pm - 8pm",
      description:
        "A celebration of music & culture   in downtown Des Moines, July 7-8, 2023.",
      description2:
        "proceeds from the festival go towards furthering DMMC’s mission of building a stronger and more diverse music scene in Des Moines",
      image: "80-35.jpeg",
      location: "Eastbank Venue & Lounge",
    },
    {
      id: 1,
      title: "237 Collective x Iowa Pop Art Fashion Show",
      date: "2023-05-05",
      time: "6pm - 8pm",
      description:
        "The fashion show will feature 12 local clothing designers and is the official pre-party for Iowa Pop Art Festival! Tickets are $25 at the door. ",
      image: "model1-front.jpeg",
      location: "Eastbank Venue & Lounge",
    },
    {
      id: 3,
      title: "Pear Fair",
      date: "2023-05-05",
      time: "6pm - 8pm",
      description:
        "The fashion show will feature 12 local clothing designers and is the official pre-party for Iowa Pop Art Festival! Tickets are $25 at the door. ",
      image: "model1-front.jpeg",
      location: "Eastbank Venue & Lounge",
    },

    {
      id: 4,
      title: "Rachel's Birthday!!",
      date: "2023-03-31",
      time: "6pm - 8pm",

      description: "Rachels BIRTHDAY!!!.",
      image: "rach_hotdog.png",
      location: "",
    },
  ]);

  const [showFutureMonths, setShowFutureMonths] = useState(true); // default unchecked

  // create an object to map events to their respective month and year
  const monthEvents: MonthEvents = {};
  events.forEach((event) => {
    const eventDate = new Date(event.date);
    const monthYear = `${eventDate.toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    })}`;
    if (!monthEvents[monthYear]) {
      monthEvents[monthYear] = [];
    }
    monthEvents[monthYear].push(event);
  });

  const currentDate = new Date();

  // convert the object to an array of objects with month and events properties
  const monthArray = Object.entries(monthEvents)
    .filter(([monthYear, events]) =>
      events.some((event) => new Date(event.date) > currentDate)
    )
    .map(([monthYear, events]) => ({
      month: monthYear,
      events: events.filter((event) => new Date(event.date) > currentDate),
    }));

  // sort the array by month
  monthArray.sort((a, b) => {
    const dateA: any = new Date(a.month);
    const dateB = new Date(b.month);
    return dateB.getTime() - dateA.getTime();
  });

  // convert the object to an array of objects with month and events properties for past events
  const pastMonthArray = Object.entries(monthEvents)
    .filter(([monthYear, events]) =>
      events.some((event) => new Date(event.date) <= currentDate)
    )
    .map(([monthYear, events]) => ({
      month: monthYear,
      events: events.filter((event) => new Date(event.date) <= currentDate),
    }));

  // sort the array by month
  pastMonthArray.sort((a, b) => {
    const dateA: any = new Date(a.month);
    const dateB = new Date(b.month);
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <PageLayout className="" title="Events">
      <Inner className="col-span-12 ml-auto mr-auto">
        <H1 className="md:text-center underline underline-offset-[20px]">
          Calendar
        </H1>
        <div className="flex align-top w-fit">
          <IonCheckbox
            // labelPlacement="start"
            checked={showFutureMonths}
            onIonChange={(e) => setShowFutureMonths(e.detail.checked)}
            className="mt-auto mb-auto"
          />
          <Label className="mt-auto mb-auto pl-1">Show future months</Label>
        </div>
        {showFutureMonths && monthArray.length >= 1 && (
          <H2 className="underline underline-offset-4 pt-6">Upcoming Events</H2>
        )}

        {/* Conditionally render monthArray */}
        {(!showFutureMonths
          ? monthArray.filter((month) => {
              const monthDate = new Date(month.month);
              return monthDate <= currentDate;
            })
          : monthArray
        ).map((month) => (
          <div key={month.month}>
            <H2 className=" text-[#CC0000] text-center pt-2 ">{month.month}</H2>
            {month.events.map((event) => (
              <div
                key={event.id}
                className="m-1 p-1 border my-1 flex flex-col md:flex-row  md:h-fit mb-12"
              >
                <div className="md:w-2/5 h-fit ">
                  <Link
                    to={"/eventdetails"}
                    className=" self-center md:self-start"
                  >
                    <ImageComponent
                      className="w-[100%] ml-auto mr-auto h-auto object-contain"
                      fileName={event.image}
                      alt={""}
                    />
                  </Link>
                </div>
                <div className="md:w-3/5 p-4 flex flex-col justify-center">
                  <Link
                    to={"/eventdetails"}
                    className=" self-center md:self-start"
                  >
                    <H3 className="underline hover:animate-pulse underline-offset-4">
                      {event.title}
                    </H3>
                  </Link>
                  <span>
                    <Label className="mt-2">
                      {moment(event.date).format("MMMM DD, YYYY")}
                    </Label>
                    <Label className="mt-2 ml-1">@ {event.time}</Label>
                  </span>
                  <Copy className="mt-2">{event.description}</Copy>
                  {event.description2 && (
                    <Copy className="mt-2">{event.description2}</Copy>
                  )}
                  <Link
                    to={`/eventdetails`}
                    className=" self-center md:self-start"
                  >
                    <IonButton>View Details</IonButton>
                  </Link>
                </div>
                {/* <div className="flex justify-center md:flex-col"></div> */}
              </div>
            ))}
          </div>
        ))}

        <H2 className="underline underline-offset-4 mt-6">Past Events</H2>

        {/* Display past events grouped by month */}
        {Object.entries(monthEvents)
          .filter(([monthYear, events]) =>
            events.some((event) => new Date(event.date) <= currentDate)
          )
          .sort((a, b) => {
            const dateA: any = new Date(a[0]);
            const dateB: any = new Date(b[0]);
            return dateB.getTime() - dateA.getTime();
          })
          .map(([monthYear, events]) => (
            <div key={monthYear} className="">
              <H2 className=" text-[#CC0000] text-center pt-8 ">{monthYear}</H2>

              {events.map((event) => (
                <div
                  key={event.id}
                  className="m-1 p-1 border my-1 flex flex-col mb-12  md:flex-row md:h-fit "
                >
                  <div className="md:w-2/5 h-fit ">
                    <Link
                      to={"/eventdetails"}
                      className=" self-center md:self-start"
                    >
                      <ImageComponent
                        className="w-[100%] ml-auto mr-auto h-auto object-contain"
                        fileName={event.image}
                        alt={""}
                      />
                    </Link>
                  </div>

                  <div className="md:w-3/5 p-4 flex flex-col justify-center">
                    <Link
                      to={"/eventdetails"}
                      className=" self-center md:self-start"
                    >
                      <H3 className="underline hover:animate-pulse underline-offset-4">
                        {event.title}
                      </H3>
                    </Link>
                    <Label className="mt-2">
                      {moment(event.date).format("MMMM DD, YYYY")}
                    </Label>
                    <Copy className="mt-2">{event.description}</Copy>
                    <Link
                      to={"/eventdetails"}
                      className=" self-center md:self-start"
                    >
                      <IonButton>View Details</IonButton>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          ))}

        <Link className="fixed bottom-3 right-5" to={"/mikeyscorner"}>
          .
        </Link>
      </Inner>
    </PageLayout>
  );
};

export default Events;
