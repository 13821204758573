import { H1 } from "../components/copy";
import { Inner } from "../components/layout";
import PageLayout from "../components/PageLayout";


const ShopItem: React.FC = () => {
  
  return (
    <PageLayout title={"Item Details"}>
      <Inner className="col-span-12 ml-auto mr-auto">
        <H1 className="text-center"> Item details page coming soon..!</H1>
      </Inner>
    </PageLayout>
  );
};

export default ShopItem;
