import { IonAlert, IonButton } from "@ionic/react";
import { useState } from "react";
import { Copy, H1 } from "../components/copy";
import ImageComponent from "../components/image";
import { Inner } from "../components/layout";
import PageLayout from "../components/PageLayout";

const MikeysCorner: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleButtonClick = () => {
    setShowAlert(true);
  };

  return (
    <PageLayout className="" title="About">
      <Inner className="col-span-12 grid grid-cols-6  ml-auto mr-auto pt-12 h-fit ">
        <div className=" bg-[#f0e6b6] md:h-[400px] md:w-[250px]  lg:h-[550px] lg:w-[400px] ml-auto mr-auto rounded-lg border flex col-span-6 md:col-span-3 items-center  hover:animate-spin ">
          <ImageComponent
            fileName="mikeyscorner.png"
            alt=""
            className="w-[80%] h-[80%] mx-auto object-cover rounded-lg hover:animate-spin"
          />
        </div>
        <div className="col-span-6 md:col-span-3  sm:-ml-14  mb-24  hover:animate-spin">
          <H1 className="">AHAHAHAHAH YAR BE MIKEY!</H1>
          <Copy>YOU'VE FOUND ME CORNER AHA AHAAHAAA AAHHHHHHH</Copy>
          <Copy>
            THIS HERE WEBSITE BE ME CREATION, ALTHOUGH RACHEL THE MAKER SERVES
            TO FILL ER WITH INSPO. I MERELY CREATE THE FUNCTIONALITY
          </Copy>
          <Copy></Copy>
        </div>
        <IonButton
          routerLink="/portfolio"
          className=" hover:animate-bounce col-span-12 "
          onClick={handleButtonClick}
        >
          COMMIT TO BEING A SCALLYWAG
        </IonButton>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header="Ahoy!"
          message="Ye be a scallywag now, me hearty!"
          buttons={["OK"]}
        />
      </Inner>
    </PageLayout>
  );
};

export default MikeysCorner;
