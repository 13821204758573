import { classFilter } from "../util/dom";

type LayoutProps = React.HTMLAttributes<HTMLDivElement>;



const Section: React.FC<LayoutProps> = (p) => {
  const { className, ...props } = p;

  return (
    <div
      {...props}
      className={classFilter(
        "flex w-screen flex-col items-center justify-between",
        className ?? ""
      )}
    />
  );
};

const Inner: React.FC<LayoutProps> = (p) => {
  const { className, ...props } = p;

  return (
    <div
      {...props}
      className={classFilter(
        "w-full max-w-[1200px] py-10 px-4",
        className ?? ""
      )}
    />
  );
};

const Hero: React.FC<LayoutProps> = (p) => {
  const { className, ...props } = p;

  return (
    <Section {...props} className={classFilter("mt-28", className ?? "")} />
  );
};

export {  Section, Inner, Hero };
