import React from "react";
import { IonFooter, IonToolbar, IonIcon } from "@ionic/react";
import { logoFacebook, logoInstagram, logoTwitter } from "ionicons/icons";
import { Copy, GridContent } from "./copy";

const Footer: React.FC = () => {
  return (
    <IonFooter className="mb-20 sm:mb-2  mt-4">
      <IonToolbar>
        <GridContent>
          <div   className=" p-1 rounded-xl col-span-12 ml-auto mr-auto    " >
         

            
            <Copy className="   ">Rachel Maker &copy; 2023</Copy>
        
          </div>
        </GridContent>
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
