import { Link } from "react-router-dom";
import { Copy, H1, H2 } from "../components/copy";
import ImageComponent from "../components/image";
import { Inner } from "../components/layout";
import PageLayout from "../components/PageLayout";

const Shop: React.FC = () => {
  return (
    <PageLayout className="" title="Shop">
      <Inner className="col-span-12 ml-auto mr-auto">
        <H1 className="sm:hidden">Shop</H1>
        <H2> Vintage </H2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="shirt.jpeg"
                className="w-full h-full object-cover"
                alt={""}
              />
              <Copy className="text-center">$28</Copy>
            </Link>
          </div>
          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="shorts.jpeg"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>

            <Copy className="text-center">$22</Copy>
          </div>
          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="swimsuit.jpeg"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>
            <Copy className="text-center">$42</Copy>
          </div>

          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="hat.jpeg"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>
            <Copy className="text-center">$25</Copy>
          </div>

          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="flowershirt.jpeg"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>
            <Copy className="text-center">$20</Copy>
          </div>
        </div>
        <H2 className="pt-10"> Handmade </H2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 ">
          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="handmade_earrings.png"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>
            <Copy className="text-center">$22</Copy>
          </div>
          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="handmade_earrings2.png"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>

            <Copy className="text-center">$22</Copy>
          </div>
          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="handmade_mushroom.png"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>
            <Copy className="text-center">$10</Copy>
          </div>
          <div className="border">
            <Link to={"/shop/viewItem"} className=" self-center md:self-start">
              <ImageComponent
                fileName="handmade_sweater.png"
                className="w-full h-full object-cover"
                alt={""}
              />
            </Link>
            <Copy className="text-center">$50</Copy>
          </div>
        </div>
      </Inner>
    </PageLayout>
  );
};

export default Shop;
