import { H2 } from "../components/copy";
import PageLayout from "../components/PageLayout";
import ContactForm from "../components/ContactForm";
import SignInComponent from "../components/firebaseFns/SignInComponent";

const Contact: React.FC = () => {
  // still need to create backend and database
  // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   const body = { name, email, phone, subject, message };
  //   fetch("/api/send-email", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(body),
  //   })
  //     .then(() => alert("Message sent!"))
  //     .catch((error) => console.error(error));
  // };

  return (
    <PageLayout title="contact" className="mx-4 sm:mx-0">
      <H2 className="col-span-12 text-center sm:-ml-32 mt-10">
        Want to get in touch?
      </H2>
      <ContactForm />
      <H2 className=" col-span-12 ml-auto mr-auto mt-12">Sign In or Register Below!</H2>
      <SignInComponent/>
    </PageLayout>
   
  );
};

export default Contact;
