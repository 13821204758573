type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  fileName?: string | undefined;
  alt: string;
};

const ImageComponent: React.FC<ImageProps> = (p) => {
  const { fileName, alt, ...props } = p;

  return (
    <img
      {...props}
      src={fileName ? require(`../assets/images/${fileName}`) : props.src}
      alt={alt}
    />
  );
};

export default ImageComponent;
