// firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbtOq-IxkZ6EDibfwL9li9iVoDicZ3MFI",
  authDomain: "rachels-server.firebaseapp.com",
  projectId: "rachels-server",
  storageBucket: "rachels-server.appspot.com",
  messagingSenderId: "524671068154",
  appId: "1:524671068154:web:c054752f31c6fbd48aa643",
  measurementId: "G-CZFMXMFJMR",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth, analytics };
