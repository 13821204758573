import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseFns/firebase-config'; // Update the path as needed
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { User } from 'firebase/auth';

const SignInComponent: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState<User | null>(null);

  // Handle input changes
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  // Handle sign-in
  const signIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user); // Set the user in state
    } catch (error) {
      console.error(error);
      // Handle errors here, such as displaying a notification
    }
  };

  // Handle registration
  const register = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user); // Set the user in state
    } catch (error) {
      console.error(error);
      // Handle errors here, such as displaying a notification
    }
  };

  // Handle Google Sign-In
  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      setUser(result.user); // Set the user in state
    } catch (error) {
      console.error(error);
      // Handle errors here, such as displaying a notification
    }
  };

  // Handle sign-out
  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clear the user in state
    } catch (error) {
      console.error(error);
    }
  };

  // Subscribe to user on auth state change
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Update the user state
    });
    return unsubscribe; // Unsubscribe on unmount
  }, []);














  
  // Conditional rendering based on the user's authentication state
  if (user) {
    // User is signed in, show their info and a logout button
    return (
      <div className="flex flex-col col-span-12 items-center justify-center ">
      <div className="p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-xl font-semibold">Welcome, {user.displayName || user.email}</h1>
        <button
          onClick={signOutUser}
          className="px-4 py-2 mt-4 w-full bg-red-500 text-white text-sm font-medium rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
        >
          Log Out
        </button>
      </div>
    </div>
    );
  } else {
    // User is not signed in, show the login/register form
    return (
      <div className="flex flex-col col-span-12  items-center justify-center  ">
      <div className="p-8 bg-white shadow-md rounded-lg">
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            placeholder="Enter email"
            id="email"
            className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md text-xl placeholder:text-xl focus:ring-1 focus:ring-blue-500"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="mb-6">
          <label htmlFor="password" className="block text-xl placeholder:text-xl font-medium text-gray-700">
            Password
          </label>
          <input
            type="password"
            placeholder="Enter password"
            id="password"
            className="mt-1 px-3 py-2 bg-white border shadow-sm border-gray-300 placeholder-gray-400 focus:outline-none focus:border-blue-500 block w-full rounded-md sm:text-sm focus:ring-1 focus:ring-blue-500"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button
          onClick={signIn}
          className="w-full mb-2 px-4 py-2 bg-blue-600 text-white text-xl font-lg rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700 text-white-50"
        >
          Log In
        </button>
        <button
          onClick={googleSignIn}
          className="w-full mb-2 px-4 py-2 bg-red-600 text-white-50 text-xl font-medium rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
        >
          Sign in with Google
        </button>
        <button
          onClick={register}
          className="w-full px-4 py-2 bg-green-600 text-white-50 text-xl font-medium rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
        >
          Register
        </button>
      </div>
    </div>
    );
  }
};

export default SignInComponent;
