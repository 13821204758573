import React, { useState } from "react";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonTextarea,
} from "@ionic/react";
import { send } from "ionicons/icons";
import { useHistory } from "react-router";

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const history = useHistory();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const mailtoLink = `mailto:rachelthemaker@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\n\nMessage: ${message}`
    )}`;

    window.location.href = mailtoLink;

    alert("Thanks for reaching out, I'll try and get back with you!");
    history.push("/portfolio");
  };

  return (
    <form
      onSubmit={handleSubmit}
      className=" grid-cols-12 grid col-span-12 sm:mx-[14rem]"
    >
      <IonItem className=" col-span-12  2xl:ml-auto 2xl:mr-auto  2xl:w-[60%]">
        <IonLabel position="floating">Name:</IonLabel>
        <IonInput
          type="text"
          value={name}
          onIonChange={(e) => setName(e.detail.value!)}
          required
        ></IonInput>
      </IonItem>

      <IonItem className=" col-span-12  2xl:ml-auto 2xl:mr-auto  2xl:w-[60%] ">
        <IonLabel position="floating">Email (optional):</IonLabel>
        <IonInput
          type="email"
          value={email}
          onIonChange={(e) => setEmail(e.detail.value!)}
        ></IonInput>
      </IonItem>

      <IonItem className=" col-span-12 2xl:ml-auto 2xl:mr-auto  2xl:w-[60%] ">
        <IonLabel position="floating">Phone (optional):</IonLabel>
        <IonInput
          type="tel"
          value={phone}
          onIonChange={(e) => setPhone(e.detail.value!)}
        ></IonInput>
      </IonItem>

      <IonItem className=" col-span-12  2xl:ml-auto 2xl:mr-auto  2xl:w-[60%] ">
        <IonLabel position="floating">Subject:</IonLabel>
        <IonInput
          type="text"
          value={subject}
          onIonChange={(e) => setSubject(e.detail.value!)}
          required
        ></IonInput>
      </IonItem>

      <IonItem className=" col-span-12  2xl:ml-auto 2xl:mr-auto  2xl:w-[60%]">
        <IonLabel position="floating">Message:</IonLabel>
        <IonTextarea
          value={message}
          onIonChange={(e) => setMessage(e.detail.value!)}
          required
        ></IonTextarea>
      </IonItem>
      <IonButton
        expand="full"
        type="submit"
        className="col-span-12 place-self-center my-6"
      >
        Submit
        <IonIcon className="ml-2" icon={send} />
      </IonButton>
    </form>
  );
};

export default ContactForm;
