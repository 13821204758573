import { IonButton, IonIcon } from "@ionic/react";
import { Copy, H1, Label } from "../components/copy";
import ImageComponent from "../components/image";
import { Inner } from "../components/layout";
import PageLayout from "../components/PageLayout";
import { logoInstagram } from "ionicons/icons";

const About: React.FC = () => {
  return (
    <PageLayout className="" title="About">
      <Inner className="col-span-12 grid grid-cols-6  ml-auto mr-auto pt-12">
        <div className=" bg-[#f0e6b6] w-11/12 mb-6 md:mb-0 md:h-[450px] md:w-[300px]  lg:h-[550px] lg:w-[400px] ml-auto mr-auto rounded-lg border flex col-span-6 md:col-span-3 items-center ">
          <ImageComponent
            fileName="rach.png"
            alt=""
            className="w-[88%] h-[90%] mx-auto object-cover rounded-lg"
          />
        </div>
        <div className="col-span-6 md:col-span-3  md:-ml-8  ">
          <H1> Hi, I'm Rachel Maker</H1>

          <Copy>It hit me at about 25 years old that my name was fitting.</Copy>
          <Copy className="mb-4 ">
            I was always hopping from one craft to the next, using what I could
            find. In middle school I learned to hand stitch. A slow lesson from
            my mom and with it, I made a top!
          </Copy>
          <Copy>
            For years I was seeking that same sense of pride that handmade piece
            of clothing gave me. Art classes of all kinds filled my school
            schedule and eventually I stumbled through college. I entered
            University hoping to be a teacher! But I didn’t see myself in the
            classes I was taking- my classmates were organized and put together
            in a way I could never figure out.
          </Copy>
          <Copy className="mt-4">
            {" "}
            On a whim I switched my studies and left college with a BA in
            Textiles and Apparel. I was making clothes and that pride was back
            and with it, a knowing!{" "}
          </Copy>
          <Copy>
            Today I design one-of-a-kind garments made with vintage textiles.
            Conservation intersects in all of the art I make.
          </Copy>

          <IonButton
            className="flex mt-4 normal-case max-w-[20rem]  ml-auto mr-auto justify-self-center"
            routerLink="/contact"
          >
            Get In Touch!
          </IonButton>
          <Copy className=" text-center mt-2">or</Copy>
          <IonButton
            href="https://www.instagram.com/rachelthemaker/"
            className="flex mt-4 normal-case  max-w-[20rem] ml-auto mr-auto justify-self-center"
          >
            <IonIcon
              className=" hover:bg-romance-200 mr-2   rounded-md"
              icon={logoInstagram}
            />{" "}
            Follow on Instagram
          </IonButton>
        </div>
      </Inner>
    </PageLayout>
  );
};

export default About;
