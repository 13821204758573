import { IonTitle } from "@ionic/react";
import { classFilter } from "../util/dom";

type GridProps = React.HTMLAttributes<HTMLDivElement>;
type HeadingProps = React.HTMLAttributes<HTMLHeadingElement>;
type CopyProps = React.HTMLAttributes<HTMLParagraphElement>;
type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;
type ContainerProps = React.LabelHTMLAttributes<HTMLDivElement>;

type PageTitleProps = React.LabelHTMLAttributes<HTMLIonTitleElement>;

const BASE_HEADING_CLASS = "font-semibold tracking-tight mb-5";

const H1: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h1
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-3xl md:text-4xl lg:text-4xl xl:text-5xl",
        className ?? ""
      )}
    >
      {props.children}
    </h1>
  );
};
const H2: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h2
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-xl sm:text-3xl",
        className ?? ""
      )}
    >
      {props.children}
    </h2>
  );
};

const H3: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h3
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "sm:text-1xl text-xl",
        className ?? ""
      )}
    >
      {props.children}
    </h3>
  );
};
const H4: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h4
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-md sm:text-lg",
        className ?? ""
      )}
    >
      {props.children}
    </h4>
  );
};

const H5: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h5
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-md sm:text-base",
        className ?? ""
      )}
    >
      {props.children}
    </h5>
  );
};

const H6: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h6
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "sm:text-md text-sm",
        className ?? ""
      )}
    >
      {props.children}
    </h6>
  );
};

const Copy: React.FC<CopyProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <p
      {...passthrough}
      className={classFilter("mb-2 text-xl leading-8 ", className ?? "")}
    >
      {props.children}
    </p>
  );
};

const GridContent: React.FC<GridProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <div
      {...passthrough}
      className={classFilter(" grid grid-cols-12 ", className ?? "")}
    >
      {props.children}
    </div>
  );
};

const Container: React.FC<ContainerProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <div
      {...passthrough}
      className={classFilter(" ", className ?? "")}
    >
      {props.children}
    </div>
  );
};

const Label: React.FC<LabelProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <label
      {...passthrough}
      className={classFilter("text-sm font-bold leading-8 ", className ?? "")}
    >
      {props.children}
    </label>
  );
};

const PageTitle: React.FC<PageTitleProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <IonTitle
      size="large"
      {...passthrough}
      className={classFilter("", className ?? "")}
    >
      {props.children}
    </IonTitle>
  );
};

export { H1, H2, H3, H4, H5, H6, Copy, Label, GridContent, PageTitle, Container };
